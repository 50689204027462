import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`De siste fem årene har vi jobbet dag og natt med å bygge opp
`}<a parentName="p" {...{
        "href": "https://trailguide.net"
      }}>{`trailguide`}</a>{`, noe vi har gjort med den største glede
og entusiasme. Trailguide er gratis for deg som syklist og uten reklame og
vi vil gjerne at det skal forbli slik fremover også. For at trailguide skal
leveres kjapt og pålitelig så må vi bruke en del kommersielle løsninger.
Med økt trafikk følger økte kostnader, og de årlige kostnadene ligger
nå på ca. 70.000 kroner i året.`}</p>
    <br />
    <p>{`Som sagt så vil vi gjerne at trailguide skal forbli gratis og mer eller mindre
reklamefritt, så mens vi jobber med å utvikle teknologi og forretningsplaner
som fungerer kommersielt for trailguide så ønsker vi å gi deg muligheten til
å bidra til driften med en frivilling donasjon til Trailguide.`}</p>
    <br />
    <p>{`Tenk på det som at trailguide er gratis, men etter en skikkelig rå dag på
sykkelen så spanderer du gjerne en øl eller to på guiden.`}</p>
    <br />
    <h4>{`Vipps en frivillig donasjon til:`}</h4>
    <p align="center">
  <img width="300" src="https://www.vipps.no/documents/61/vipps-rgb-orange-neg.svg" />
    </p>
    <p className="text-center text-4xl mt-6 text-orange-700 font-bold">571734</p>
    <br />
    <br />
    <Image src="news/peter_bjorn_gunther.jpg" mdxType="Image" />
    <p className="text-xs mb-16" align="center">
  Peter Seidl (innholdsansvarlig og evangelist)
  <br />
  Bjørn Jarle Kvande (programmerer)
  <br />
  Güntherwagen (markedsføring, transport og overnatting)
  <br />
  Foto: Gry Svantesvold
    </p>
    <p>{`Oversikt over tjenester som brukes:`}</p>
    <ul>
      <li parentName="ul">{`Thunderforest karttjenester`}</li>
      <li parentName="ul">{`Google karttjenester`}</li>
      <li parentName="ul">{`Google lokasjonstjenester`}</li>
      <li parentName="ul">{`Google språktjenester`}</li>
      <li parentName="ul">{`Cloudinary bildetjenester`}</li>
      <li parentName="ul">{`Amazon Web services`}</li>
      <li parentName="ul">{`Rollbar feilrapportering`}</li>
      <li parentName="ul">{`IT-administrasjon`}</li>
      <li parentName="ul">{`Domener/sertifikater`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      